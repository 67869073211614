<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_FST_CD"
            itemText="codeName"
            itemValue="code"
            label="상위구분"
            name="patrolFstCd"
            v-model="searchParam.patrolFstCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_SEC_CD"
            itemText="codeName"
            itemValue="code"
            label="하위구분"
            name="patrolSecCd"
            v-model="searchParam.patrolSecCd">
          </c-select>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 추진년도 -->
          <c-datepicker
            type="year"
            label="추진년도"
            name="patrolYear"
            v-model="searchParam.patrolYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-dept-multi
            label="추진부서(다중 선택 가능)"
            name="deptCds"
            v-model="deptCds"
          />
        </div>
      </template>
    </c-search-box>
    <c-card title="월별 부서별 지적사항 건 수" class="cardClassDetailForm bodyOverflow-none">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-12">
          <apexchart 
            ref="chart" 
            height="500px" 
            type="line"
            :options="chartOptions" 
            :series="series"></apexchart>
        </div>
      </template>
    </c-card>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'patrol-month-graph',
  props: {
    patrolInfo: {
      type: Object,
      default(){
        return {
          patrolFstCd: null,
          typeDisabled: false,
        }
      },
    },
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      chartHeight: 500,
      searchParam: {
        plantCd: null,
        patrolYear: '',
        deptCds: [],
        patrolFstCd: null,
        patrolSecCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
      },
      deptCds: '',
      period: [],
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      chartOptions: {
        // title: {
        //   text: '월별 부서별 지적사항 건 수'
        // },
        annotations: {
          yaxis: [],
        },
        // 그래프 설정
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: true
          },
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
        },
        yaxis: [
          {
            title: {
              text: '건'
            },
            labels: {
              formatter: function(val) {
                return Number.isInteger(val) ? val.toFixed(0) + '건' : val.toFixed(1) + '건';
              }
            }
          }
        ],
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        fill: {
          opacity: 1
        },
        markers: {
          size: 1,
        },
      },
      series: [],
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      if (this.patrolInfo.typeDisabled) {
        this.searchParam.patrolFstCd = this.patrolInfo.patrolFstCd
      }
      this.searchParam.patrolYear = this.$comm.getThisYear();
      // url setting
      this.listUrl = selectConfig.sai.patrol.graph.itemresult.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.series = [];
      // if (this.period && this.period.length > 0) {
      //   this.searchParam.startYmd = this.period[0];
      //   this.searchParam.endYmd = this.period[1];
      // } else {
      //   this.searchParam.startYmd = '';
      //   this.searchParam.endYmd = '';
      //   return;
      // }

      if (this.deptCds) {
        this.searchParam.deptCds = this.deptCds.split(',')
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.series.push({
            name: _item.dept_name,
            data: [_item.sum01, _item.sum02, _item.sum03, _item.sum04, _item.sum05, _item.sum06, 
                   _item.sum07, _item.sum08, _item.sum09, _item.sum10, _item.sum11, _item.sum12]
          })
        })  

        setTimeout(() => {
          this.$refs['chart'].refresh();
        }, 200);
      },);
    },
    setSize() {
      let height = window.innerHeight - 340;
      height = height >= 400 ? height : 400
      this.chartHeight = height;
    },
    // periodchange() {
    //   if (!this.period || this.period.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내', // 안내
    //       message: '해당년월을 선택하세요.', // 해당년월을 선택하세요.
    //       type: 'warning', // success / info / warning / error
    //     });
    //     return;
    //   }
    //   let startYear = Number(this.period[0].substring(0, 4));
    //   let startMonth = Number(this.period[0].substring(5));

    //   let lastYear = Number(this.period[1].substring(0, 4));
    //   let lastMonth = Number(this.period[1].substring(5));
      
    //   this.searchParam.period = [];
    //   this.chartOptions.xaxis.categories = [];

    //   if (startYear === lastYear) {
    //     for (let month=startMonth; month<=lastMonth; month++) {
    //       let monthStr
    //       if (month < 10) {
    //         monthStr = '0' + month
    //       } else {
    //         monthStr = month
    //       }
    //       this.searchParam.period.push(startYear+'-'+monthStr+'sum');
    //     }
    //   } else {
    //     for (let year=startYear; year<=lastYear; year++) {
    //       if (year===startYear) {
    //         for (let month=startMonth; month<=12; month++) {
    //           let monthStr
    //           if (month < 10) {
    //             monthStr = '0' + month
    //           } else {
    //             monthStr = month
    //           }
    //           this.searchParam.period.push(year+'-'+monthStr+'sum');
    //         }
    //       } else if (year===lastYear) {
    //         for (let month=1; month<=lastMonth; month++) {
    //           let monthStr
    //           if (month < 10) {
    //             monthStr = '0' + month
    //           } else {
    //             monthStr = month
    //           }
    //           this.searchParam.period.push(year+'-'+monthStr+'sum');
    //         }
    //       } else {
    //         for (let month=1; month<=12; month++) {
    //           let monthStr
    //           if (month < 10) {
    //             monthStr = '0' + month
    //           } else {
    //             monthStr = month
    //           }
    //           this.searchParam.period.push(year+'-'+monthStr+'sum');
    //         }
    //       } 
    //     }
    //   }
    //   this.getList();
    // },
  }
};
</script>
